import { render, staticRenderFns } from "./peoples.vue?vue&type=template&id=73cd2a04&scoped=true"
import script from "./peoples.vue?vue&type=script&lang=js"
export * from "./peoples.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cd2a04",
  null
  
)

export default component.exports